import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Height of Fullscreen Window
    fullscreenWindowHeight: window.innerHeight + "px",

    // Active Window State
    activeWindow: "BiographyWindow",

    // Active Windows Array State
    activeWindows: [],

    // Z-index State
    zIndex: 2,

    photoFiles: [
      {
        id: 0,
        title: "Leica Q",
        content: [
          {
            id: 0,
            title: "Q-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Leica Q/Q-3.JPG",
            altText: "Q-3.JPG",
            size: 2477506,
          },
          {
            id: 1,
            title: "Q-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Leica Q/Q-2.JPG",
            altText: "Q-2.JPG",
            size: 1265051,
          },
          {
            id: 2,
            title: "Q-1.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Leica Q/Q-1.JPG",
            altText: "Q-1.JPG",
            size: 1366527,
          },
        ],
        size: 5109084,
        type: "folder",
        altText: "Leica Q",
      },
      {
        id: 1,
        title: "Yashica FX-7",
        content: [
          {
            id: 0,
            title: "FX7-8.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-8.JPG",
            altText: "FX7-8.JPG",
            size: 2705525,
          },
          {
            id: 1,
            title: "FX7-1.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-1.JPG",
            altText: "FX7-1.JPG",
            size: 3285824,
          },
          {
            id: 2,
            title: "FX7-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-2.JPG",
            altText: "FX7-2.JPG",
            size: 2524860,
          },
          {
            id: 3,
            title: "FX7-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-3.JPG",
            altText: "FX7-3.JPG",
            size: 2126399,
          },
          {
            id: 4,
            title: "FX7-7.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-7.JPG",
            altText: "FX7-7.JPG",
            size: 2391497,
          },
          {
            id: 5,
            title: "FX7-6.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-6.JPG",
            altText: "FX7-6.JPG",
            size: 1903950,
          },
          {
            id: 6,
            title: "FX7-4.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-4.JPG",
            altText: "FX7-4.JPG",
            size: 2660198,
          },
          {
            id: 7,
            title: "FX7-5.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Yashica FX-7/FX7-5.JPG",
            altText: "FX7-5.JPG",
            size: 720153,
          },
        ],
        size: 18318406,
        type: "folder",
        altText: "Yashica FX-7",
      },
      {
        id: 2,
        title: "Mamiya RB67",
        content: [
          {
            id: 0,
            title: "RB67-17.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-17.JPG",
            altText: "RB67-17.JPG",
            size: 4018715,
          },
          {
            id: 1,
            title: "RB67-16.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-16.JPG",
            altText: "RB67-16.JPG",
            size: 6310380,
          },
          {
            id: 2,
            title: "RB67-14.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-14.JPG",
            altText: "RB67-14.JPG",
            size: 5023300,
          },
          {
            id: 3,
            title: "RB67-15.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-15.JPG",
            altText: "RB67-15.JPG",
            size: 4324262,
          },
          {
            id: 4,
            title: "RB67-11.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-11.JPG",
            altText: "RB67-11.JPG",
            size: 3841978,
          },
          {
            id: 5,
            title: "RB67-10.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-10.JPG",
            altText: "RB67-10.JPG",
            size: 1776580,
          },
          {
            id: 6,
            title: "RB67-12.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-12.JPG",
            altText: "RB67-12.JPG",
            size: 2880049,
          },
          {
            id: 7,
            title: "RB67-13.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-13.JPG",
            altText: "RB67-13.JPG",
            size: 3496524,
          },
          {
            id: 8,
            title: "RB67-8.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-8.JPG",
            altText: "RB67-8.JPG",
            size: 3183608,
          },
          {
            id: 9,
            title: "RB67-9.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-9.JPG",
            altText: "RB67-9.JPG",
            size: 3620249,
          },
          {
            id: 10,
            title: "RB67-4.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-4.JPG",
            altText: "RB67-4.JPG",
            size: 4628923,
          },
          {
            id: 11,
            title: "RB67-5.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-5.JPG",
            altText: "RB67-5.JPG",
            size: 3756349,
          },
          {
            id: 12,
            title: "RB67-7.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-7.JPG",
            altText: "RB67-7.JPG",
            size: 4537069,
          },
          {
            id: 13,
            title: "RB67-6.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-6.JPG",
            altText: "RB67-6.JPG",
            size: 2526863,
          },
          {
            id: 14,
            title: "RB67-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-2.JPG",
            altText: "RB67-2.JPG",
            size: 3589326,
          },
          {
            id: 15,
            title: "RB67-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-3.JPG",
            altText: "RB67-3.JPG",
            size: 2662097,
          },
          {
            id: 16,
            title: "RB67-1.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-1.JPG",
            altText: "RB67-1.JPG",
            size: 3956155,
          },
          {
            id: 17,
            title: "RB67-22.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-22.JPG",
            altText: "RB67-22.JPG",
            size: 1231755,
          },
          {
            id: 18,
            title: "RB67-23.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-23.JPG",
            altText: "RB67-23.JPG",
            size: 2724006,
          },
          {
            id: 19,
            title: "RB67-21.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-21.JPG",
            altText: "RB67-21.JPG",
            size: 1118255,
          },
          {
            id: 20,
            title: "RB67-20.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-20.JPG",
            altText: "RB67-20.JPG",
            size: 1028996,
          },
          {
            id: 21,
            title: "RB67-18.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-18.JPG",
            altText: "RB67-18.JPG",
            size: 4362951,
          },
          {
            id: 22,
            title: "RB67-19.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Mamiya RB67/RB67-19.JPG",
            altText: "RB67-19.JPG",
            size: 4082215,
          },
        ],
        size: 78680605,
        type: "folder",
        altText: "Mamiya RB67",
      },
      {
        id: 3,
        title: "Leica M4-P",
        content: [
          {
            id: 0,
            title: "M4P-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Leica M4-P/M4P-2.JPG",
            altText: "M4P-2.JPG",
            size: 616758,
          },
          {
            id: 1,
            title: "M4P-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Leica M4-P/M4P-3.JPG",
            altText: "M4P-3.JPG",
            size: 671916,
          },
          {
            id: 2,
            title: "M4P-1.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Leica M4-P/M4P-1.JPG",
            altText: "M4P-1.JPG",
            size: 432534,
          },
        ],
        size: 1721208,
        type: "folder",
        altText: "Leica M4-P",
      },
      {
        id: 4,
        title: "Canon FTb",
        content: [
          {
            id: 0,
            title: "FTb-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Canon FTb/FTb-3.JPG",
            altText: "FTb-3.JPG",
            size: 1853391,
          },
          {
            id: 1,
            title: "FTb-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Canon FTb/FTb-2.JPG",
            altText: "FTb-2.JPG",
            size: 294620,
          },
          {
            id: 2,
            title: "FTb-1.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Canon FTb/FTb-1.JPG",
            altText: "FTb-1.JPG",
            size: 832777,
          },
        ],
        size: 2980788,
        type: "folder",
        altText: "Canon FTb",
      },
      {
        id: 5,
        title: "Minolta Dynax",
        content: [
          {
            id: 0,
            title: "Dynax-4.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-4.JPG",
            altText: "Dynax-4.JPG",
            size: 1565244,
          },
          {
            id: 1,
            title: "Dynax-5.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-5.JPG",
            altText: "Dynax-5.JPG",
            size: 1424551,
          },
          {
            id: 2,
            title: "Dynax-7.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-7.JPG",
            altText: "Dynax-7.JPG",
            size: 1878078,
          },
          {
            id: 3,
            title: "Dynax-6.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-6.JPG",
            altText: "Dynax-6.JPG",
            size: 1467924,
          },
          {
            id: 4,
            title: "Dynax-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-2.JPG",
            altText: "Dynax-2.JPG",
            size: 1929487,
          },
          {
            id: 5,
            title: "Dynax-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-3.JPG",
            altText: "Dynax-3.JPG",
            size: 1827869,
          },
          {
            id: 6,
            title: "Dynax-1.jpg",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-1.jpg",
            altText: "Dynax-1.jpg",
            size: 1505052,
          },
          {
            id: 7,
            title: "Dynax-11.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-11.JPG",
            altText: "Dynax-11.JPG",
            size: 2111385,
          },
          {
            id: 8,
            title: "Dynax-10.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-10.JPG",
            altText: "Dynax-10.JPG",
            size: 1575287,
          },
          {
            id: 9,
            title: "Dynax-12.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-12.JPG",
            altText: "Dynax-12.JPG",
            size: 952801,
          },
          {
            id: 10,
            title: "Dynax-8.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-8.JPG",
            altText: "Dynax-8.JPG",
            size: 1650754,
          },
          {
            id: 11,
            title: "Dynax-9.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Minolta Dynax/Dynax-9.JPG",
            altText: "Dynax-9.JPG",
            size: 1816302,
          },
        ],
        size: 19704734,
        type: "folder",
        altText: "Minolta Dynax",
      },
      {
        id: 6,
        title: "Rolleiflex K4A",
        content: [
          {
            id: 0,
            title: "K4A-1.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Rolleiflex K4A/K4A-1.JPG",
            altText: "K4A-1.JPG",
            size: 4533313,
          },
          {
            id: 1,
            title: "K4A-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Rolleiflex K4A/K4A-2.JPG",
            altText: "K4A-2.JPG",
            size: 2715035,
          },
          {
            id: 2,
            title: "K4A-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Rolleiflex K4A/K4A-3.JPG",
            altText: "K4A-3.JPG",
            size: 3321761,
          },
          {
            id: 3,
            title: "K4A-6.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Rolleiflex K4A/K4A-6.JPG",
            altText: "K4A-6.JPG",
            size: 2095538,
          },
          {
            id: 4,
            title: "K4A-4.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Rolleiflex K4A/K4A-4.JPG",
            altText: "K4A-4.JPG",
            size: 1720820,
          },
          {
            id: 5,
            title: "K4A-5.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Rolleiflex K4A/K4A-5.JPG",
            altText: "K4A-5.JPG",
            size: 2221798,
          },
        ],
        size: 16608265,
        type: "folder",
        altText: "Rolleiflex K4A",
      },
      {
        id: 7,
        title: "Fuji G617",
        content: [
          {
            id: 0,
            title: "G617-3.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Fuji G617/G617-3.JPG",
            altText: "G617-3.JPG",
            size: 1783412,
          },
          {
            id: 1,
            title: "G617-2.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Fuji G617/G617-2.JPG",
            altText: "G617-2.JPG",
            size: 1720995,
          },
          {
            id: 2,
            title: "G617-1.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Fuji G617/G617-1.JPG",
            altText: "G617-1.JPG",
            size: 1729038,
          },
          {
            id: 3,
            title: "G617-5.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Fuji G617/G617-5.JPG",
            altText: "G617-5.JPG",
            size: 1506793,
          },
          {
            id: 4,
            title: "G617-4.JPG",
            type: "file",
            src: "https://d3eubay99a85fr.cloudfront.net/Win95 Photos/Fuji G617/G617-4.JPG",
            altText: "G617-4.JPG",
            size: 1570263,
          },
        ],
        size: 8310501,
        type: "folder",
        altText: "Fuji G617",
      },
      {
        windowId: "RAGBlogWindow",
        windowState: "close",
        displayName: "RAG Blog",
        windowComponent: "window",
        windowContent: "RAGBlog",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "8vw",
        positionY: "15vh",
        iconImage: "rag-blog.png",
        altText: "RAG Blog",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
    ],

    photoFolderContent: [],

    windows: [
      {
        windowId: "BiographyWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Biography", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "bio", // Window Content (used under slots)
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "5vw", // Window Position X (when first opened)
        positionY: "5%", // Window Position Y (when first opened)
        iconImage: "bio.png", // Window Icon Image
        altText: "Biography", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "ResumeWindow", // Unique ID
        windowState: "close", // Window State [open, close, minimize]
        displayName: "Résumé", // Display Name (title under icon)
        windowComponent: "window", // Window Component (can be changed to use modified windows)
        windowContent: "resume", // Window Content (used under slots)
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        }, // Window Content Padding
        position: "absolute", // Window Position
        positionX: "10vw", // Window Position X (when first opened)
        positionY: "15vh", // Window Position Y (when first opened)
        iconImage: "resume.png", // Window Icon Image
        altText: "Résumé", // Window Icon Alt Text
        fullscreen: false, // Window Fullscreen State [true, false]
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "MailWindow",
        windowState: "close",
        displayName: "Mail",
        windowComponent: "mail",
        windowContent: "",
        windowContentPadding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
        },
        position: "absolute",
        positionX: "6vw",
        positionY: "12vh",
        iconImage: "mail.png",
        altText: "Mail",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
      {
        windowId: "RAGBlogWindow",
        windowState: "close",
        displayName: "RAG Blog",
        windowComponent: "window",
        windowContent: "RAGBlog",
        windowContentPadding: {
          top: null,
          right: null,
          bottom: null,
          left: null,
        },
        position: "absolute",
        positionX: "8vw",
        positionY: "15vh",
        iconImage: "rag-blog.png",
        altText: "RAG Blog",
        fullscreen: false,
        showInAppGrid: true,
        showInNavbar: true,
      },
    ],
    mailContent: "",
    mailSender: "",
    mailSubject: "New Message",
  },
  mutations: {
    // Active Window Mutator
    setActiveWindow(state, window) {
      console.log(window);
      state.activeWindow = window;
    },

    pushNewWindow(state, window) {
      state.windows.push(window);
    },

    // Pushes Active Window onto Active Windows Array
    pushActiveWindow(state, window) {
      state.activeWindows.push(window);
    },

    // Removes Active Window from Active Windows Array
    popActiveWindow(state, window) {
      const windowIndex = state.activeWindows.indexOf(window);
      if (windowIndex !== -1) {
        state.activeWindows.splice(windowIndex, 1);
      }
    },

    // Z-index increment function
    zIndexIncrement(state, windowID) {
      state.zIndex += 1;
      let element = document.getElementById(windowID);
      if (element) {
        element.style.zIndex = state.zIndex;
      }
    },

    // Set height of max-height of fullscreen window
    setFullscreenWindowHeight(state, height) {
      state.fullscreenWindowHeight = height;
    },

    updateMail(state, local) {
      state.isShownMail = local;
    },
    updateMailSubject(state, local) {
      state.mailSubject = local;
    },
    updateMailContent(state, local) {
      state.mailContent = local;
    },
    updateMailSender(state, local) {
      state.mailSender = local;
    },

    setFullscreen(state, payload) {
      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();
      window.fullscreen = payload.fullscreen;
    },

    setPhotoFolderContent(state, payload) {
      state.photoFolderContent = payload;
    },

    setCurrentVideo(state, payload) {
      state.currentVideo = payload;
    },

    // Window State Mutator
    setWindowState(state, payload) {
      // payload = {'windowState': 'open', 'windowID': 'WindowOne'}

      function getArrItem() {
        return state.windows.find(
          (windows) => windows.windowId === payload.windowID
        );
      }
      const window = getArrItem();

      var preventAppendingOpenWindow = false;
      if (window.windowState == "open" || window.windowState == "minimize") {
        preventAppendingOpenWindow = true;
      }

      if (payload.windowState == "open") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("zIndexIncrement", payload.windowID);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", payload.windowID);
        }, 0);
        if (preventAppendingOpenWindow == false) {
          setTimeout(() => {
            this.commit("pushActiveWindow", window);
          }, 0);
        }
      } else if (payload.windowState == "close") {
        window.windowState = payload.windowState;
        setTimeout(() => {
          this.commit("popActiveWindow", window);
        }, 0);
        setTimeout(() => {
          this.commit("setActiveWindow", "nil");
        }, 0);
      } else if (payload.windowState == "minimize") {
        window.windowState = payload.windowState;
        this.commit("setActiveWindow", "nil");
      } else {
        console.log("Error: windowState not found or invalid");
      }
    },
  },
  getters: {
    // Active Window Getter
    getActiveWindow: (state) => {
      return state.activeWindow;
    },

    // Window Getter
    getWindowById: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id);
    },

    getWindowFullscreen: (state) => (id) => {
      return state.windows.find((window) => window.windowId === id).fullscreen;
    },

    getPhotoFolderContent(state) {
      return state.photoFolderContent;
    },

    getPhotoFiles: (state) => {
      return state.photoFiles;
    },

    getSongAlbumFiles: (state) => {
      return state.songAlbumFiles;
    },

    getVideoFiles: (state) => {
      return state.videoFiles;
    },

    getCurrentVideo: (state) => {
      return state.currentVideo;
    },

    getWindows: (state) => {
      return state.windows;
    },

    getActiveWindows(state) {
      return state.activeWindows;
    },

    getFullscreenWindowHeight(state) {
      return state.fullscreenWindowHeight;
    },

    mailContent: (state) => {
      return state.mailContent;
    },

    mailSubject: (state) => {
      return state.mailSubject;
    },

    mailSender: (state) => {
      return state.mailSender;
    },
  },
});
