import { render, staticRenderFns } from "./Wwdc2021.vue?vue&type=template&id=40c0e035&scoped=true"
var script = {}
import style0 from "./Wwdc2021.vue?vue&type=style&index=0&id=40c0e035&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c0e035",
  null
  
)

export default component.exports