<template>
  <div class="app-grid">
    <nav class="grid-container" :style="{ height: gridHeight }">
      <li v-for="window in windows" :key="window.key">
        <button
          class="icon"
          v-if="window.showInAppGrid != false"
          @touchstart="openWindow(window.windowId)"
          @dblclick="openWindow(window.windowId)"
        >
          <img
            class="icon-image"
            :src="require('../assets/win95Icons/' + window.iconImage)"
            :alt="window.altText"
          />
          <div class="border">
            <p class="icon-text">
              {{ window.displayName }}
            </p>
          </div>
        </button>
      </li>
    </nav>
    <button class="app" @click="openWindow('RAGBlogWindow')">
      <img class="icon app-rag-blog" src="../assets/win95Icons/rag-blog.png" />
      <div class="border">
        <div class="icon-text">RAG Blog</div>
      </div>
    </button>
  </div>
</template>

<style scoped>
.icon-text {
  font-size: 12px;
  font-weight: 300;
}

.icon:hover {
  cursor: pointer;
}
</style>

<script>
export default {
  name: "AppGrid",
  data: function () {
    return {
      windows: this.$store.getters.getWindows,
      gridHeight: "",
    };
  },
  methods: {
    openWindow(windowId) {
      if (windowId === "RAGBlogWindow") {
        history.pushState(null, "", "/rag");
      }
      this.$store.commit("setWindowState", {
        windowState: "open",
        windowID: windowId,
      });
    },
    openGithub() {
      window.open("https://github.com/DonChiaQE");
    },
    openRAGBlog(e) {
      e.stopPropagation();
      this.$store.commit("setWindowState", {
        windowState: "open",
        windowID: "RAGBlogWindow",
      });
      this.$store.commit("setActiveWindow", "RAGBlogWindow");
      setTimeout(() => {
        this.$store.commit("zIndexIncrement", "RAGBlogWindow");
      }, 1);
    },
  },
  mounted() {
    let gridH = this.$store.getters.getFullscreenWindowHeight;
    this.gridHeight = gridH.substring(0, gridH.length - 2) - 60 + "px";
  },
};
</script>
